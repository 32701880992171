*{
    margin: 0;
    padding: 0;
}

:root {
    --blue: #1554bb;
    --hover_blue: #407fe5;
    --white: #ffffff; 
    --old_purple: #3600C5;
    --primary_yellow: #FDD955;
    --grey: #f3f3f3;
    --warning:rgb(255, 87, 87);
    --black:#000;
    --grey_2: #d9d9d9;

  }

.Page-Outer{
    /* background-color: yellow; */
    padding: 50px;
}

/* .........................Main Dashboard page ................................................... */


.dashboard_panel{
    padding-top: 20px;
    width: 100%;
}
.chart_Heading{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}
.chart_area{
    display: flex;
    gap: 50px 30px;
    grid-template-columns: auto auto;
    width: 100%;
    flex-wrap: wrap;
}
.Cha_rt{
    padding: 20px;
    border: 1px solid var(--grey_2);
    border-radius: 20px;
}
.Cha_rt img{
    max-width: 500px;
}

@media screen and (max-width:767px){
    
   
    .Cha_rt img{
        max-width: 300px;
    }
    .create_outer1 h4 {
        height: 50px;
    }
    .container{
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width:475px){
    
    .Cha_rt img{
        max-width: 200px;
    }
    .check_list12 a{
        font-size: 12px;
    }
    .create_outer1 h4 {
        font-size: 14px;
    }

}

.about_banner_Outer{
    position: relative;
    /* background-image: url("https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='About Banner"); */
    /* background-color: #fff1c1; */
    width: 100%;
    /* height: 200px ; */
    object-fit: cover;
}
.About_b_heading{
    font-size: 59px;
    font-weight: 800;
    color: var(--black);
    /* position: absolute; */
    outline: var(--black);
    margin-top: 100px;
    text-align: center;
}
.about_banner_Outer img{
    z-index: 1;
}
.about_banner_Outer img::after{
    background-color: #1554bb;
    z-index: 2; 
    width: 100%;
    height: 100%;
}

.about_container{
    width: 90%;
    max-width: 1600px;
    padding: 0px 50px;
    margin: 50px auto;
    background-color: var(--white) ;
    border-radius: 30px;
    text-align: center;
}
.privacy_policy{
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 30px ;
}
.footer-li a:hover{
    color: var(--black);
    font-weight: 800;
}
.About_banner{
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.footer-category-heading-main{
    text-decoration: none;
    color: var(--black);
}
.footer-category-heading-main:hover{
    font-weight: 800;
    color: var(--black);  
}

.social_media{
    width: 20px ;
    text-align: center;
}
.footer-back{
    max-width: 2000px;
    margin: auto;
}
.profile_normal img{
    width: 50px ;
    height: 50px;
}

.Pricing_Banner{
    max-width: 2000px;
    width: 100%;
    margin: auto;
    padding: 80px 50px;
    background-image: linear-gradient(-180deg, #1554bb, #2272f1);
    height: 400px;
    /* z-index: -1; */
}

.head_Text{
    font-size: 59px;
    font-weight: 800;
    margin: auto;
    color: var(--white);
    text-align: center;
}
.Tagline{
    font-size: 24px;
    margin: auto;
    color: var(--white);
    text-align: center;
}
/* .Pricing_B_text{
    
} */
.Tier{
    padding-bottom: 0px;
    margin: auto;
    font-weight: 600;
    font-size: 18px;
}
.pricing_card_outer{
    margin: -130px auto 50px;
    z-index: 20;
    padding: 20px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    max-width: 1170px;

}
.Pricing_card{
    width: 30%;
    background-color: var(--white);
    border-radius: 30px;
}
.Pricing_C_top{
    text-align: center;
    padding: 20px;
}
.Divider{
    border-bottom: 4px solid #ffc107;
    max-width: 100px;
    margin: 0px auto 20px;
    border-radius: 20px;
}
.Plan_Name{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.price{
    font-size: 36px;
    font-weight: 800;
}
.post_bottom{
    font-weight: 400;
    font-size: 18px;
}
.pricing_features{
    padding: 0px 20px 20px;
}
.Buy_btn{
    border: 2px solid var(--primary_yellow);
    color: var(--primary_yellow);
    border-radius: 15px;
    padding: 12px 20px;
    font-size: 18px ;
    font-weight: 600;
}
.Buy_btn:hover{
    border: 2px solid var(--primary_yellow);
    background-color: var(--primary_yellow) ;
    color: var(--black);
}
.Buy_btn:focus{
    border: 2px solid var(--primary_yellow);
    background-color: #ffc107 ;
    color: var(--black);
}
.Pricng_point{
    padding: 10px;
}
.Pricng_point li::marker{
    content: url("../../public/images/check-solid.svg");
    background-color: #ffc107;
    fill: var(--primary_yellow);
    color: var(--primary_yellow);
}
.picing_item i{
    color: var(--primary_yellow);
    font-weight: 600;
    font-size: 21px;
}
.picing_item p{
    margin-bottom: 0;
}
.picing_item{
    display: flex;
    padding: 10px 0;
}

.custom_header .navbar-toggler{
    background-color: #000;
    height: 40px;
    border: none !important;
}
.custom_header .navbar-toggler:hover{
    background-color: #000;
    height: 40px;
    border: none !important;
}
.custom_header .navbar-toggler:focus{
    background-color: #000;
    height: 40px;
    border: none !important;
}
.custom_header .navbar-toggler .navbar-toggler-icon .fa{
    padding-top: 5px;
}
.custom_header .navbar-toggler:focus .navbar-toggler-icon .fa{
    padding-top: 5px;
}
.custom_header .navbar-toggler:hover .navbar-toggler-icon .fa{
    /* padding-top: 5px; */
}
.custom_header .navbar-toggler:visited .navbar-toggler-icon .fa{
    padding-top: 0px;
}
.navbar-toggler-icon .fa{
    display: block !important;
    color: var(--primary_yellow);
    padding-top: 5px;
}
.collapsed .navbar-toggler-icon .fa{
    padding-top: 0px;
}
.custom_header .navbar-toggler.collapsed{
    padding-top: 5px;
}
.custom_header .navbar-toggler.collapsed:hover{
    padding-top: 5px;
}

.Events_Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.more_actions{
    display: flex;
    flex-direction: row;
}
.more_actions a {
    text-decoration: none;
    color: var(--blue);
}
.Head_back{
    background-color: var(--grey_2);
}
.horzont_line{
    margin-top: 20px;
}
.Add_new_Btn{
    margin: 10px 0 10px;
    background-color: var(--blue) !important;
}

.form_Edit_event{
    max-width: 850px;
    padding: 20px;
    border-radius: 30px;
    margin: 50px auto;
    border: 1px solid var(--grey_2);

}
.Form_Heading{
    font-size: 24px;
    padding: 10px 0;
    font-weight: 600;
}



@media screen and (max-width:768px)

{
    .pricing_card_outer{
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        padding: 50px;
        gap: 50px;
    }
    .Pricing_card{
        width:320px;
        /* max-width: ; */
    }
}

@media screen and (max-width:767px)

{
    .pricing_card_outer{
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
        padding: 50px;
        gap: 50px;
    }
    .Pricing_card{
        width:100%;
        /* max-width: ; */
    }
    .Events_Header{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    th, td {
        min-width: 150px;
    }
    .Page-Outer{
        padding: 30px 20px;
    }
    

    
}
