*{
    margin: 0;
    padding: 0;
}

/* h1{
    color: yellowgreen;
} */

:root {
    --blue: #1554bb;
    --hover_blue: #407fe5;
    --white: #ffffff; 
    --old_purple: #3600C5;
    --primary_yellow: #FDD955;
    --grey: #f3f3f3;
    --warning:rgb(255, 87, 87);
    --black:#000;
    --grey_2: #d9d9d9;
    --success: #157347;

  }

  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 80px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }
  
  .sidebar .nav-link.active {
    color: #2470dc;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
   * Navbar
   */
  
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }
  .serch_head{
    border: 1px solid var(--blue) !important;
    border-radius: 10px !important;

  }
  .serch_head::placeholder{
    background-image: url("../css/Imgs/search.svg");
    background-repeat: no-repeat;
    
    
  }

  .Ad_head{
    background-color: var(--grey_2);
    align-items: center !important;
  }
  .ad_head_full{
    height: 40px;
  }

  .Ad_avatar img{
     width: 50px ;
     height: 50px;
  }
  .fas {
    width: 20px;
    text-align: center;
    margin-right: 10px;
  }
  .events_row{
    display: flex;
    gap: 30px;
    /* grid-template-columns: auto auto auto ; */
    padding: 10px;
    flex-wrap: wrap;
  }
  .event_card{
    padding: 1rem;
    border-radius: 20px !important;
    border: none !important;
    position: relative;
    width: 100%;
  }
  .event_card h5{
    width: calc(100% - 60px);
  }
  .event_card::before{
    border: none !important;
    padding: 10px;
    border-radius:20px;
    position: absolute;
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient( -45deg, #0586ff 30%, #008afc 100%);
    filter: blur(8px);
    transform: translate(0px , 10px ,0);
  }

  .event_card:focus::before{
    background: linear-gradient( -45deg, #f9ff48 30%, #e5ff39 100%);
    filter: blur(8px);
    transform: translate(0px , 10px ,0);
  }
  .Event_owner{
    font-size: 16px;
    color: var(--grey_2);
  }
  .Eve_Discri{
    font-size: 14px;
    height: 40px;
    overflow: hidden;
    color: var(--grey_2);
  }

  .manage_it{
    text-decoration: none;
  }
  .event_list_head{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Eve_status{
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 600;
    font-size: 14px;
    padding: 8px;
    background-color: var(--primary_yellow);
    color: var(--white);
    border-radius: 10px;
  }

  .Eve_closed{
    background-color: var(--warning);
  }
  .Eve_open{
    background-color: var(--success);
  }
  .header_right_panel{

  }
  .Page_box{
    padding: 30px 10px 30px;
  }

  .Welcome_text{
    color: var(--blue);
    font-weight: 600;
    font-size: 16px;
    margin: 20px;
  }



  @media screen and (max-width:991px)
  {
    .Eve_status{
      font-size: 12px;
    }
    .events_row{
      gap: 20px;
      grid-template-columns: auto auto;
      padding: 10px;
    }
  }

  @media screen and (max-width:600px)
  {
    .Eve_status{
      font-size: 12px;
    }
    .events_row{
      gap: 20px;
      grid-template-columns: auto;
      padding: 10px;
    }
  }


.subscription_table th {
    min-width: 150px !important;
}
.subscription_table th:nth-child(1) {
  min-width: 50px !important;
}
.Page_box_subscription {
  overflow-y: scroll;
}

.side_list_style .nav-item .active{
  background-color: #008afc;
  color: #fff;
}
.su_btn_box{
  justify-content: flex-end;
}