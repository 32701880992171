*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
:root {
    --blue: #1554bb;
    --hover_blue: #407fe5;
    --white: #ffffff; 
    --old_purple: #3600C5;
    --primary_yellow: #FDD955;
    --grey: #f3f3f3;
    --black: #000;
  }

.header{
    text-align: center;
    background: #FFDB56; 
}
.container-header{
    /* max-width: 1170px; */
     display: flex;
     margin: auto;
     align-items: center;
     height: 124px;
     justify-content: space-around;
}
.container .logo{
    flex: 0 0 20%;
}
.menu{
    display: flex;
    flex: 0 0 80%;
}
.header-left{
    display: flex;
    flex: 0 0 40%;
}
.header-left h1{
    align-self: center;
}

.header-left ul{
    display: flex;
    text-decoration: none;
    list-style: none;
    font-size: 18px; 
}
.navbar-nav li a{
    font-weight: 400 !important;
    color: black;
}
.header-left ul li{
    margin: 20px;
}
.header-right{
    flex: 0 0 40%;
    margin: auto;
    text-align: right;

}
.header-right ul{
    display: flex;
    text-decoration: none;
    list-style: none;
    font-size: 18px;
    justify-content: flex-end;
}
.header-right ul li{
    margin-right: 30px;
    align-self: center;
}
.header-right ul li button{
    padding: 14px 69px;
    background: #3600C5;
    color: #fff;
    border-radius: 50px;
    border:none;
    font-size: 18px;
}
.homeBanner{
    max-width: 1170px;
    margin: auto;
}
.banner-Content{
    max-width: 1100px;
    margin: 20px auto ;
    padding: 80px 20px 50px;
    text-align: center;
}
.banner-Content h1 span{
    border-bottom: 2px solid black;
}
.homeBanner h1{
    font-size: 59px;
    font-weight: 800;
    line-height: 120%;
}
.homeBanner p{
    margin-top: 50px;
    font-weight: 400;
    font-size: 24px;
}
.banner-btn{
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.banner-btn a{
    color: var(--black) !important;
}
.create-event a{
    color: var(--white) !important;
}
.banner-btn button{
    padding: 18px 48px;
    border-radius: 30px;
    border:none;
    font-size: 36px;
    cursor: pointer;
}
.banner-btn a { 
    align-self: center;
    font-size: 36px;
    text-decoration: none;
    color: #fff;
}
.banner-btn a:nth-child(2) { 
    margin-left: 35px;
}

.create-event{
    background: var(--blue);
    color: #fff;
    font-weight: 700;
    font-size: 36px;
}
.section_container{
    margin: auto;
    align-items: center;
}
.features-box{
    padding: 80px 50px 0px;
    text-align: center;
}
.features-heading{
    font-size: 59px ;
    font-weight: 700;
}
.features-subHeading{
    font-size: 24px;
}
.feature-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 100px 10px;
    max-width: 1170px;
    margin: auto;
    justify-content: space-between;
}
.feature_content{
    flex: 0 0 50%;
}
.feature_content .title_text{
    font-size: 48px;
    font-weight: 600;
}
.feature_content .paragraph_text{
    font-size: 24px;
}
.feature-img{
    flex: 0 0 40%;
    text-align: right;
}
.feature-img svg{
    fill: var(--blue);
}
.feature-img img{
    width: 100%;
    border-radius: 20px;
}
.reverse-alignment{
    text-align: left !important;
}

.dark-back{
    background-color: #f3f3f3;
}
.about-link{
    text-decoration: none;
    color: #000;
}
.mirror-phase{
    flex-direction: row-reverse !important;
}
.content-align{
    padding-left: 30px;
}
.footer-back{
    background-color: #FFDB56;
}
.footer-part{
    max-width: 1240px;
    margin: auto;
    padding: 60px 10px;
}
.footer-content{
    display: flex;
    flex-direction: row;
}
.footer-links{
    flex: 0 0 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer-ul{
    width: 20%;
}
.footer-li{
    list-style: none;
}
.footer-head{
    flex: 0 0 40%;
}
.footer-category-heading{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}
.footer-category-heading-main{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}
.footer-li-main{
    font-size: 18px;
}
.footer-li{
    font-size: 14px;
    line-height: 180%;
}
.footer-li a{
    text-decoration: none;
    color: #000;
}
.copyright-tagline{
    margin-top: 50px;
}

@media screen and (max-width:1024px) {
    .features-box{
        padding: 80px 50px;
    }
    .feature_content .title_text{
        font-size: 48px;
    }
    .feature_content .paragraph_text{
        font-size: 18px;
    }
    .features-heading{
        font-size: 64px;
    }
    .feature-section {
        padding: 80px 50px;
    }
}


@media screen and (max-width:768px) {
    .homeBanner{
        max-width: 768px;
        margin: auto;
    }
    .banner-Content{
        margin-left: 30px;
    }
    .banner-Content h1{
        line-height: 130%;
        font-size: 48px;
    }

    .features-box{
        padding: 80px 50px 0;
    }
    .feature_content .title_text{
        font-size: 48px;
    }
    .feature_content .paragraph_text{
        font-size: 18px;
    }
    .features-heading{
        font-size: 48px;
    }
    .feature-section {
        padding: 50px 50px;
    }
    .features-subHeading{
        font-size: 18px;
    }
    .footer-content{
        flex-direction: column;
        
    }
    .footer-part{
        text-align: center;
    }
    .footer-links{
        margin-top: 30px;
    }
    .footer-category-heading{
        margin-bottom: 10px;
    }
    .footer-category-heading-main{
        margin-bottom: 15px;
    }
    .footer-ul{
        /* padding-left: 0 !important; */
        text-align: left !important;
    }
    .feature-img{
        flex: 0 0 50%;
        text-align: right;
    }
}

@media screen and (max-width:767px) {
    .banner-btn button{
        padding: 8px 23px;
        font-size: 20px;
    }
    .banner-btn a{
        font-size: 20px;
    }
    .banner-Content{
        max-width: 400px;
    }
    .homeBanner p{
        font-size: 14px;
    }
    .features-box {
        padding: 40px 20px 0;
    }
    .features-heading {
        font-size: 36px;
    }
    .features-subHeading {
        font-size: 16px;
    }
    .feature-section {
        flex-direction: column-reverse;
        padding: 50px;
    }
    .feature_content .title_text {
        font-size: 32px;
    }
    .feature_content {
        flex: 0 0 100%;
    }
    .feature-img {
        flex: 0 0 100%;
        text-align: right;
        padding: 30px;
    }
    .content-align {
        padding:0;
    }
    .feature-img svg {
        height: 250px;

    }
    .mirror-phase {
        flex-direction: column-reverse !important;
    }
    .footer-ul {
        width: 30%;
    }
    .footer-part  {
        padding: 30px 10px;
    }
    .copyright-tagline {
        margin-top: 30px;
    }

}
@media screen and (max-width:530px) {
    .feature-section {
        flex-direction: column-reverse;
        padding: 50px 20px;
    }
    .banner-Content {
        padding: 0 20px;
    }
    .feature-img svg {
        height: 200px;
    }
    
    .footer-ul {
        width: 100%;
    }
}
@media screen and (max-width:425px) {
    .homeBanner{
        max-width: 425px;
        margin: auto;
    }
    .banner-Content{
        margin-left: 45px;
    }
    .homeBanner h1{
        font-size: 64px;
        font-weight: 800;
        line-height: 74px;

    }
    .banner-btn button{
        padding: 12px 15px; 
        border-radius: 20px;
        border:none;
        font-size: 24px;
        cursor: pointer;
    }
    .banner-btn a:nth-child(2) { 
        margin-left: 28px;
        font-size: 24px;
    }
    
}

@media screen and (max-width:375px) {
    .feature-section {
        padding: 30px 15px;
    }
    .banner-btn button {
        padding: 8px 15px;
        font-size: 18px;
    }
    .banner-btn {
        margin-top: 30px;
    }
    .banner-btn a {
        margin-left: 20px;
        font-size: 18px;
    }
    .banner-Content h1 {
        line-height: 130%;
        font-size: 40px;
    }
    .homeBanner p {
        margin-top: 30px;
    }
    .features-heading{
        line-height: 130%;
    }
    .banner-Content {
        padding: 0 20px;
    }

}

@media screen and (max-width:320px) {
    .homeBanner{
        max-width: 320px;
        margin: auto;
    }
    .banner-Content{
        margin-left: 15px;
        margin-top: 50px;
    }
    .homeBanner h1{
        font-size: 36px;
        font-weight: 800;
        line-height: 50px;

    }
    .banner-btn{
        flex-direction: column;
    }
    .banner-btn button{
        padding: 12px 15px;
        border-radius: 20px;
        border:none;
        font-size: 20px;
        cursor: pointer;
    }
    .banner-btn a:nth-child(2) { 
        margin-left: 0px;
        font-size: 20px;
        margin-top: 20px;
    }
    .footer-category-heading{
        font-size: 14px;
    }
    .footer-ul {
        padding-left: 10px !important;
    }
    
}