body {
    font-family: 'Poppins', sans-serif !important;

}
:root {
    --blue: #1554bb;
    --hover_blue: #407fe5;
    --white: #ffffff; 
    --old_purple: #3600C5;
    --primary_yellow: #FDD955;
    --grey: #f3f3f3;
    --warning:rgb(255, 87, 87);
    --black:#000;
    --grey_2: #d9d9d9;

  }

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
.input_outer.date45 .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}
.input_outer.date45.bot .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border-top: transparent !important;
    border-bottom: 1px solid #ccc;
    border-left: transparent;
    border-right: 1px solid #ccc;
    opacity: 0.7;
}
fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 0;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background-color: #F3F3F3;
}
.start_dark {
    color: #000 !important;
}
  .Toastify__toast-body div {
    font-size: 14px;
}
  .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg {
    fill: #FFDB56; 
}
  .custom_header .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    background-color: #ffdb56;
    border: 1px solid transparent;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.btn-outline-success:hover {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}
 .desktop_hide{
    display:none;
}
.multiSelectContainer li {
    padding: 0px 10px !important;
}

.search-wrapper.searchWrapper {
    border: none !important;
    padding: 0px !important;
    min-width: auto;
    position: relative !important;
    height: 48px !important;
    /* overflow-y: hidden !important; */
    overflow-x: auto;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blue); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blue); 
}
.custom_header .navbar-toggler.collapsed {
    border: 1px solid #000;
    font-size: 22px;
    padding-top: 11px;
} 
.msl.false {
    border: none;
    width: 100%;
}
.msl-wrp.msl-vars.undefined {
    margin: -43px 0px 0px 60px;
    border: none !important;
    background-color: #fff;
    width: 80%;
}
.react-datepicker__input-container input {
    width: 100%;
    border: none;
    /* margin: 0px 0px 0px 50px; */
}
.react-datepicker-wrapper {
    /* width: 90px; */
    margin-top: 0px;

}
.cutom_plus {
    display: flex;
    align-items: center;
}
.custom_header {
    background-color: #ffdb56;
    max-width: 2000px;
    margin: auto;
}
.easy_event {
    color: #000;
    font-weight: 700;
    font-size: 28px;
    margin-top: 6px;
}
.customcontainer {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #F3F3F3;
    padding: 10px;
    width: 100%;
    padding: 30px;
    border-radius: 20px;
    color: #000;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    border: 1px solid #fff;
    overflow-wrap: anywhere;
}
.contact-add-on{
    color: #000 !important;
    background-color: #ffc107 !important;
    border: none;

}

.contact-add-on:hover{
    color: #000 !important;
    background-color: #ffc107 !important;
    border: none;
}


/* Hide the browser's default checkbox */
.customcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.customcontainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid #f3f3f3 !important;
}


/* When the checkbox is checked, add a blue background */
.customcontainer input:checked ~ .checkmark {
    /* background-color: transparent; */
    border: 2px solid var(--blue) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.customcontainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customcontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none !important;
}
.rental_outer {
    margin-bottom: 20px;
    text-align: center;
}
.not-found {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: red;
    margin-top: -30px;
}
/* The container */
.radiocontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    padding: 50px;
}
.radio-1{
    min-height: 180px;;
}

.p_text {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 16px !important;
}
.creating_event.pop-modalCustom {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.createEventBtn {
    margin: 0 auto;
    display: block;
    background-color: #ffc107;
    border: none;
    font-size: 24px;
    font-weight: 600;
    color: #181818;
    padding: 20px 30px;
    border-radius: 30px;
}
  /* Hide the browser's default radio button */
  .radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
  
  /* Create a custom radio button */
  .radiocontainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f3f3f3;
    border-radius: 30px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none !important;
}
  
  /* When the radio button is checked, add a blue background */
  .radiocontainer input:checked ~ .checkmark {
    border: 2px solid var(--blue) !important;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radiocontainer .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radiocontainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radiocontainer .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
      display: none !important;
  }
.custom_labe8 input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

.custom_labe8 input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.custom_labe8 input::-ms-input-placeholder { /* Microsoft Edge */
   color: #ccc;
}
.custom_labe8.cutom_decriton input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
  
.custom_labe8.cutom_decriton input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

.custom_labe8.cutom_decriton input::-ms-input-placeholder { /* Microsoft Edge */
   color: #ccc;
}
.custom_name5 input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--blue);
  opacity: 1; /* Firefox */
}
  
.custom_name5 input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--blue);
}   

.custom_name5 input::-ms-input-placeholder { /* Microsoft Edge */
   color: var(--blue);
}

.monda_outer .custom_labe8.cutom_decriton input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}
    
.monda_outer .custom_labe8.cutom_decriton input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.monda_outer .custom_labe8.cutom_decriton input::-ms-input-placeholder { /* Microsoft Edge */
   color: #000;
}
.custy8_outer4 {
    padding: 9px;
    height: 180px;
    overflow-x: inherit;
    overflow-y: scroll;
    margin-bottom: 20px;
}
.custom_tn8 .btn.btn-outline-success {
    border: transparent;
    font-weight: 600;
    color: #000 !important;
    font-size: 18px;
}
.custom_tn8 .btn.btn-primary {
    background-color: #3600c5;
    padding: 10px 50px;
    border-radius: 50px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
}
.crete_event {
    background-color: var(--blue);
    color: #fff;
    text-decoration: none;
    padding: 13px 30px;
    display: inline-block;
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
    border-radius: 24px;
}
.crete_event:hover {
    background-color: var(--hover_blue);
    color: #fff;
}
.create_event_butn{
    padding: 8px 8px !important;
}

.learn_more {
    color: #afafaf;
    font-size: 24px;
    margin-left: 10px;
    font-weight: 400;
}
.right_img img {
    width: 50%;
}
.arrow_down {
    margin: 80px auto 50px;
    text-align: center;
    cursor: pointer;
}
.inner_bos6 {
    text-align: left;
}
.inner_bos6.buget5 {
    margin-left: 100px;
    margin-top: 50px;
}
.inner_bos6 p {
    font-size: 18px;
}
.meet_event a {
    color: #000;
    font-size: 18px;
}
.inner_bos6 h3 {
    font-size: 34px;
    font-weight: 600;
    margin-top: 30px;
    color: #000;
}
.specing670 {
    margin: 0px 0px 200px;
}
.feture_title {
    margin-bottom: 100px;
}
.feture_title h1 {
    font-size: 46px;
    font-weight: 600;
    color: #000;
}
.home_banner {
    margin: 50px 200px;
}
.feature_outer {
    margin: 50px 200px;
}
.btn_outer42 {
    margin-top: 50px;
}
.inner_banner h3 {
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 40px;
}
.custom_tn8 .btn.btn-primary:hover {
    background-color: var(--hover_blue);

}
.inner_banner {
    text-align: left;
}
.custom_header .navbar.navbar-expand-lg.bg-light {
    background-color: transparent !important;
}
.custom_header .navbar-nav.me-auto.mb-2.mb-lg-0 {
    margin-left: 60px;
}
.custom_header .navbar-nav.me-auto.mb-2.mb-lg-0 li a {
    color: #000;
    padding: 0px 20px;
}
.container99 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}
.table.custom_table tr td {
    padding-bottom: 10px;
}
.custom_ty6 {
    width: 37px;
    text-align: center;
}
.custom_ty6 img{
    margin-top: 10px;
    cursor: pointer;
}
.cuts8 {
    width: 38px;
}
/* Hide the browser's default checkbox */
.container99 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #E5E5E5;
  border-top:2px solid #ccc !important;
   border-right:2px solid #ccc !important;
   border:1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container99:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container99 input:checked ~ .checkmark {
  background-color: #E5E5E5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container99 input:checked ~ .checkmark:after {
  display: block;
}

 
/* Style the checkmark/indicator */
.container99 .checkmark:after {
    left: 9px;
    top: 2px;
    width: 7px;
    height: 15px;
    border: solid var(--blue);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}   
.table.custom_table tr th {
    font-size: 12px;
    font-weight: 400;
    color: #B5B5B5;
}  
.table.custom_table tr {
    border: transparent;
}
.cutom_plus {
    border: 1px solid #ccc;
    border-style: dotted;
    padding: 0;
    height: 45px;
    line-height: 43px;
}  
.cutom_plus {
    color: #B5B5B5;
    font-weight: 400;
}
.cutom_plus img {
    width: 30px;
    margin-right: 20px;
    margin-top: -1px;
    margin-left: 7px;
}

.cutom_plus.custom_name5 input {
    border: transparent;
    width: 100%;
    text-align: center;
}

.custom_labe8 img {
    position: absolute;
    right: 20px;
    z-index: 100;
    padding-top: 14px;
}
.custom_nag6 {
    width: 320px;
}
.custom_labe8 {
    position: relative;
    cursor: pointer;
    width: 450px;
}
.cutom_plus {
    margin-left: 10px;
}
.custom_labe8 input {
    width: 100%;
    padding: 10px;
    background-color: #F3F3F3;
    border: 1px solid #ccc;
    border-bottom: transparent;
}

.custom_labe8 input.task_name.utm_custom_value {
  
    padding-right: 42px;
}

.title_heading {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.accordion{
  margin: 40px 0;
}
.accordion .item {
    border: none;
    margin-bottom: 10px;
    background: none;
    border: 1px solid #ccc;
    border-radius: 20px 20px 10px 10px;
}
.right90 img {
    margin-top: -5px;
}   
.right90 a {
    background-color: #FFDB56;
    padding: 9px 10px;
    border-radius: 10px;
    color: #000;
    text-decoration: none;
    font-weight: 600;
    /* margin-right: 0px; */
    font-size: 13px;
}   
.right90 {
    display: inline-block;
    float: right;
    margin-top: 8px;
}
.accordion .t-p {
    color: rgb(193 206 216);
    padding: 9px;
    height: 180px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.accordion .item .item-header h2 button.btn.btn-link {
    background: var(--blue);
    color: white;
    border-radius: 10px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 2.5;
    text-decoration: none;
    padding: 6px;
    height: 43px;
}
.accordion .item .item-header {
    border-bottom: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
}  
/* .item-header:hover {
    background-color: #3600C5 !important;
}     */
 .accordion .item .item-header h2 button {
    color: white;
    font-size: 20px;
    padding: 6px 20px;
    display: inline-block;
    width: 100%;
    text-align: left;
    border-radius: 5px;
}  
.accordion .item .item-header h2 i {
    font-size: 30px;
    color: #fff;
    /* margin-right: 70px; */
}  
   
.accordion .btn.btn-link.collapsed i {
    transform: rotate(0deg);
}

.accordion .btn.btn-link i {
    transform: rotate(180deg);
    transition: 0.5s;
}
.left_tabs ul {
    list-style: none;
    padding: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.check_list {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    justify-content: center;
    max-width: 2000px;
}
.left_tabs {
    width: 14%;
    background-color: #f3f3f3;
    max-width: 187px;
}
.left_tabs ul li {
    text-align: center;
    border-bottom: 1px solid #e9e5e5;
    padding-top: 50px;
    padding-bottom: 50px;
    cursor: pointer;
}
.check_list12.active {
    background-color: var(--blue);
    color: var(--white);
}
.check_list12.active a  {
    fill: #fff !important;
    color: #fff;
}
.check_list12:hover a  {
    fill: #fff !important;
    color: #fff !important;
    stroke: #fff !important;
}
.check_list12:hover a svg{
    fill: #fff !important;
    stroke: #fff !important;
}
.check_list12 a {
    text-decoration: inherit;
    color: #000;
}
.check_list12:hover {
    background-color:var(--hover_blue);
}
.check_list12.active a:hover {
    color: #fff;
}
.check_list12.active a {
    color: #fff;
}
.check_right {
    width: 86%;
    text-align: left;
    padding: 20px;
}
.check_right hr {
    margin: 0;
}
.assign_outer h2 {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 20px;
}
.listy9 {
    margin: 20px 0px ;
}
.listy9 a {
    background-color: var(--blue);
    padding: 10px 32px;
    font-weight: 600;
    font-size: 20px;
    border-radius: 7px;
}
.check_list62 {
    text-align: right;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
}

.list15 {
    display: inline-block;
    margin-left: 10px;
    border: 2px solid #000;
    padding: 7px 10px;
    cursor: pointer;
    height: 48px;
    vertical-align: bottom;
}
.check_list62 select {
    padding: 5px 23px;
    font-weight: 600;
    border: 2px solid;
    display: inline-block;
    height: 48px;
    border-radius: 0;
}
.monda_outer h3 {
    font-weight: 700;
    font-size: 24px;
    color: #000;
}
.check_list12.active h4 {
    color: #fff;
}

.left_tabs ul li h4 {   
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
}
.crete_oter8 a:hover {
    background-color: #ffe996;
    color: #000;
}
.rental_outer a {
    background-color: #F3F3F3;
    padding: 10px;
    width: 100%;
    display: inline-block;
    padding: 30px;
    border-radius: 20px;
    color: #000;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    border: 1px solid #fff;
}
.custom_retal7 {
    margin: 30px 50px;
}

.get_heading h1 {
    font-weight: 600;
    margin-bottom: 20px;
}

.get_heading p span {
    font-size: 12px;
}
.btn_outer.poular_outer {
    /* margin-top: 50px; */
}
.get_heading {
    margin: 50px auto !important;
    text-align: center !important;
}
.btn_outer.poular_outer a {
    padding: 20px 40px;
}
.get_heading select {
    padding: 7px 50px;
    background-color: transparent;
    border: 2px solid #000;
    font-weight: 600;
    margin-bottom: 20px;
}
.rental_outer a:hover {
    border: 1px solid #000;
}
.get_start {
    background-color: #FFDB56;
    padding: 30px 0px;
    /* min-height: 100vh; */
    max-width: 2000px;
    margin: auto;
}
.create_outer1.new_cre4 h4 {
    background-color: #fff;
    font-size: 15px;
}
.create_outer1.new_cre4 p {
    margin-bottom: 8px !important;

}
.crey8 a {
    background-color: #fff;
    width: 100%;
    border: transparent;
    color: #000;
    font-size: 38px;
    font-weight: 700;
    border-radius: 30px;
    padding: 70px;
}
.crey8 a:hover {
    background: rgba(255, 255, 255, 0.5) !important;
    border: transparent;
    color: #000;
}

.GetStarted-1{
    background-color:#fff;
    width: 100%;
    padding: 70px 100px !important;
}

.GetStarted-1:hover{
    background: rgba(255, 255, 255, 0.5) !important;
    color: #000;
    border: 2px solid transparent;
    
}



/* .Help-center-btn:hover {
    background: rgba(255, 255, 255, 0.5) !important;
    color: #000;
    border: none;
    border: 2px solid transparent;
}
.Help-center-btn:focus{
    background:rgba(255, 255, 255, 0.5) !important;
    color:#000;
} */

.creating_event {
    background-color: #FFDB56;
    padding: 200px 0px;
	height:100vh;
}
.row.top_specing8 {
    margin-top: 50px;
    margin-bottom: 15px;
}
.crete_oter8 a {
    background-color: #FFf;
    border: transparent;
    color: #000;
    font-size: 38px;
    font-weight: 700;
    padding: 80px 60px;
    border-radius: 40px;
}
.create_outer1 h4 {
    border: 2px solid var(--blue);
    padding: 15px;
    font-weight: 700;
    font-size: 17px;
    overflow-wrap: break-word;
    height: 55px;
    border-radius: 10px;
    text-align: left;
}

header .nav-link.active {
    font-weight: 600;
	color: #000 !important;
}
.create_outer1 p {
    text-align: left;
}   
header .navbar-nav.mr-auto.mb-2.mb-lg-0 {
    margin-left: 60px;  
}  
header .navbar-nav.ms-auto.mb-2.mb-lg-0 li {
    padding-left: 70px;    
}       
header .navbar-nav.ms-auto.mb-2.mb-lg-0 li a {
    font-weight: 600;
    color: #E5E5E5;
}     
header .navbar-nav.ms-auto.mb-2.mb-lg-0 li a:hover, header .nav-link .active:hover {
    color: var(--blue) !important;
} 
.btn_outer .btn:hover {
    background-color: var(--hover_blue);
	border:1px solid var(--hover_blue);
 
}  
header .navbar.navbar-expand-lg.navbar-light.bg-light {
    background-color: transparent !important;
} 
.input_outer {
    margin-top: 50px;
}
.location_field{
    /* margin-top: 0; */
}
.middle_art p {
    margin-bottom: 3px;
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-style: normal !important;
}   
.planing_box1 h4 {
    font-weight: 700;
}
.planing_box1 p {
    font-style: italic;
}
.middle_art p span {
    margin-bottom: 3px;
    color: #000;
    font-size: 12px;
}      
.planing_box1 {
    background-color: var(--grey);
    border-radius: 30px;
    padding: 20px;
    text-align: center;
    border: 2px solid var(--grey);
    margin: 20px 0;
    position: relative;
}
.planing_box1 input:checked{
    border:2px solid var(--blue) !important;
    cursor: pointer;
}


/* .planing_box1:hover, .planing_box1:focus-within  {
    border: 2px solid #1554bb;	
	
}       */
.inner_heading h4 {
    text-align: center;
    font-size: 38px;
    font-weight: 400;
} 
  textarea:focus, input:focus{
    outline: none;
} 
.planing_outer {
    margin: 50px;
} 
.input_outer input {
    background-color: #F3F3F3;
    padding: 28px;
    width: 80%;
    border: none;
    text-align: center;
    margin-bottom: 0px;
    color: #000;
    font-size: 26px;
    border-radius: 30px;

}
.input_outer button {
    background-color: #F3F3F3;
    padding: 28px;
    width: 100%;
    border: none;
    text-align: center;
    margin-bottom: 0px;
    color: #c4c5cf;
    font-size: 26px;
    border-radius: 30px;
}
.input_outer button:hover {
    background-color: #F3F3F3;
    border: none;
    color: #c4c5cf;
}
.input_outer button:focus {
    background-color: #F3F3F3;
    border: none;
    color: #c4c5cf;
    box-shadow: none;
}
.input_outer .show>.btn-primary.dropdown-toggle{
    background-color: #f3f3f3 ;
    color: #c4c5cf;
    border: none;
}
.input_outer .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}

 /*.input_outer input:focus {
    border:2px solid var(--blue);
}*/

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C4C4C4;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C4C4C4;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #C4C4C4;
}
/* .input_outer p {
    font-size: 16px;
    margin-bottom: 110px;
} */
.btn_outer {
    margin: 0 auto;
    text-align: center;
}
.btn_outer p {
    font-size: 14px;
}

.row.top_ma56 {
    margin-top: 60px;
} 
.input_outer.date45 p {
    height: 20px;
    margin-bottom: 10px !important;
    color: #D8D8D8;
    font-size: 14px;
}
/* .even_box1.step61 {
    padding: 60px 0px;
} */
/* .btn_outer.no_edit p {
    margin-bottom: 80px;
} */
.input_outer.with_text label {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 400;
}
.input_outer.with_text.no_t5 input {
    border: transparent;
}
.input_outer.with_text.no_t5 input:focus{
    border:2px solid var(--blue);
}
.input_outer.with_text input {
    width: 100%;
    text-align: left;
}   
.btn_outer.top_p54 {
    margin-top: 90px;
}
.radiocontainer h3 {
    z-index: 9;
    position: relative;
}
.even_box1 {
    background-color: #F3F3F3;
    padding: 0px;
    border-radius: 30px;
    margin: 20px 0px;
    border: 2px solid #F3F3F3;
    text-align: center;
}
/* .even_box1:hover {
	border: 2px solid #1554bb;
	
} */
.nav-link:focus {
    color: #000 !important;
}
.even_box1 h3 {
    font-weight: 700;
    font-size: 18px;
}
.text_outer.atten {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}
.text_outer {
    margin-top: 50px;
    text-align: center;
}  
.input_outer.date45.bot input {
    background-color: #F3F3F3 !important;

}  
.input_outer.date45 input {
    width: 100%;
    border: transparent;
}
/*.input_outer.date45 input:focus {
    border:2px solid var(--blue);
}*/
.planing_box1 input {
    width: 220px;
    left: 0;
    position: absolute;
    right: 0;
    margin: 0 auto;
    top: 0;
    z-index: 999999;
    height: 100%;
    opacity: 0;
    font-weight: 300 !important;
}
.btn_outer .btn {
    background-color: var(--blue);
    padding: 17px 80px;
    font-weight: 600;
    border-radius: 20px;
    opacity: 1;
}
.input_outer.date45 p {
    margin-bottom: 0;
    text-align: left;
}

.input_outer.date45 {
    margin-top: 0;
}

.foot-step{
    padding: 20px 15px;
    background-color: #FFDB56;
    border-right: 5px solid var(--blue);
    width: 150px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;  
    position: fixed;
    bottom: 6%;
    right: 2.5%;
    z-index: 5;
    text-align: center;
}

.checklist-svg svg{
    /* color: #3600C5 !important; */
    fill: #000  !important;
}
.check_list12.active .checklist-svg svg{
    /* color: #3600C5 !important; */
    fill: #fff  !important;
}

.mainheader_profile{
    text-align: right !important;
}



@media (max-width: 1199.98px) { 
.create_outer1 h4 {
    font-size: 18px;
}
.create_outer1.new_cre4 h4 {
    background-color: #fff;
    font-size: 13px;
}
.rental_outer a {
    font-size: 18px;

}
.inner_bos6 h3 {

    margin-top: 0;

}
.feture_title h1 {
    font-size: 44px;

}
.radio-1{
    min-height: 200px;;
}
.foot-step{
    padding: 10px 4px;
    width: 100px;
    font-size: 14px;
    bottom: 3%;
    right: 2%;
    border-right: 3px solid var(--blue);
}
}
 @media screen and (max-width: 991.98px) {
	 .input_outer input {
    width: 100%;

}
.para {
    width: 100% !important;
    text-align: center;
}
	 .home_banner {
    margin: 30px 20px;
}
.specing670 {
    margin: 0px 0px 80px;
}
.feture_title {
    margin-bottom: 50px;
}
.feature_outer {
    margin: 50px 20px;
}
.inner_bos6 p br {
    display:none;
}
	 .left_tabs {
    width: 20%;
    background-color: #f3f3f3;
}
.check_right {
    width: 80%;
    text-align: left;
    padding: 20px;
}
.custom_labe8 img{
	display:none;
}
.planing_box1 {
    padding: 10px;
    margin: 30px 0px 0px;
}
.crete_oter8 a {
    font-size: 24px;
    font-weight: 700;
    padding: 24px 50px;
    border-radius: 30px;
} 
.create_outer1 h4 {
    font-size: 16px;
    height: 53px;
}
.btn_outer p {
    font-size: 14px;
    margin-top: 30px;
}
#navbarSupportedContent {
    position: absolute;
    top: 70px;
    background-color: var(--blue);
    border: 1px solid #ccc;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 20px 10px;
    z-index: 9;
}
header .navbar-nav.ms-auto.mb-2.mb-lg-0 li {
    padding-left: 0;
    text-align: left;
    padding: 0px 10px;
}
header .container {
    max-width: 100%;
}
.rental_outer {
    margin-bottom: 20px;
} 
.custom_retal7 .col-lg-4 {
    padding: 0px;
} 
.top_p54 {
    margin-top: 30px;
}
.custom_retal7 {
    margin: 0;
}
.accordion .t-p {
    color: rgb(193 206 216);
    padding: 9px;
    height: 180px;
    overflow-x: inherit;
    overflow-y: scroll;
}
.table-responsive {
    overflow-x: auto;
    width: 990px;
    overflow-y: hidden;
}
.radio-1{
    min-height: 200px;;
}
.custom_header .navbar-nav.me-auto.mb-2.mb-lg-0 {
    margin-left: 0px;
}
.custom_header .navbar-nav.me-auto.mb-2.mb-lg-0 li a {
    color: #fff;
    padding: 0px 20px;
}
.custom_tn8 .btn.btn-outline-success {
    color: #fff !important;
}
.custom_tn8 .btn.btn-primary {
    background-color: #fff;
    color: var(--blue);
}
.custom_tn8{
    padding: 10px ;
    border-top: 1px solid #8e8e8e;
}
.features-dropdown{
    background-color: var(--blue);
}
.features-dropdown .dropdown-item{
    text-align: center;
}
.nav-link:focus {
    color: #fff !important;
}

}
   
   @media (max-width: 767.98px) {
    .footer-links {
        display: initial !important;
  
    }

    .create_outer1.new_cre4 {
        margin-top: 20px;
    }
	   .desktop_hide{
		   
		   display:block;
	   }
	   .cutom_plus {
    height: auto;
}
.cutom_plus img {
    width: 30px;
    margin-right: 20px;
}
	   .custom_retal7 .rental_outer {
    margin-bottom: 20px;
}
	   .row.top_ma56 {
    margin-top: 20px;
}
.get_heading h1 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 24px;
}
.right90 a {
    margin-right: 20px;
    font-size: 13px;
    text-decoration: none;
}
.title_heading {
    font-size: 20px;
    font-weight: 600;
}

.input_outer.with_text.no_t5 {
    margin-top: 0;
}
	   .input_outer {
    margin-top: 30px;
}

.input_outer p {
    font-size: 12px;
    margin-bottom: 80px;
}
	  .inner_heading h4 {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
} 
.planing_outer {
    margin: 30px 0px;
}
	 .check_list62 {
    margin-bottom: 20px;
    
}
.feture_title h1 {
    font-size: 36px;
}
.inner_bos6 h3 {
    font-size: 26px;

}
.inner_bos6.buget5 {
    margin-left: 0;
    margin-top: 0;
}
.specing670 {
    margin: 0px 0px 20px;
}
.right_img img {
    width: 30%;
}
.mobile_hide{
		  display:none; 
	   }
	   .right_img {
    list-style: lao;
    text-align: left;
    margin-bottom: 30px;
}
   }


   @media (max-width: 575.98px) {
	.check_list {
    display: inherit;

}   
 .left_tabs {
    width: 100%;
    background-color: #f3f3f3;
}
	.check_right {
    width: 100%;  
    text-align: left;
    padding: 20px;
}  
.radio-1{
    min-height: 138px;;
}

   }
.custom-error {
    color: red !important;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    text-align: center;
}
.para {
    width: 80%;
    text-align: center;
}

.Help-center-btn {
    border-radius:12px;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    font-size: 17px;
    font-weight: bold;
    padding: 6px 25px;
    height: 42px;
}
.Help-center-btn:hover {
    background: rgba(255, 255, 255, 0.5) !important;
    color: #000;
    border: none;
    border: 2px solid transparent;
}
.Help-center-btn:focus{
    background:rgba(255, 255, 255, 0.5) !important;
    color:#000;
}
.not-checked-item{
    font-size: 24px;
    font-weight: 400;
}
.estimate-5{
    max-width: 1145px;
    margin: auto;
}
.active-border {
    border: 2px solid var(--blue);
}
.test-1{
    border: 2px solid var(--blue);
}
.MuiAvatar-root{
    width: 64px !important;
    height: 64px !important;
}
.create_outer1 .new_cre4{
    text-align: --webkit-center;
}
.input-boxes-id{
    align-items: flex-end;
}
.input-boxes{
    height: 42px;
    border-radius: 12px;
    border:2px solid var(--blue);
    background-color: #fff !important;
    font-size: 14px; 
    font-weight: 700;
}
.input-boxes:focus{
    outline: none;
    border-color: none;
    box-shadow: none;
}
.get-1{
    min-height: 100vh;
}

.radio-1 {
    min-height: 180px;
    margin-top: 10p;
}
.radiocontainer h3 {

    margin-top: 0.5rem;
}
.fa-calendar {
    position: relative;
    left: 80px;
    top: 67px;
    z-index: 10;
    font-size: 40px;
}
.delete-btn{
    /* position: absolute; */
    /* margin-left: -50px; */
    margin-top: 5px;

}
/* .down-btn{
    margin-left: 50px !important;
} */

.calender-imag{
    z-index: 1;
    position: absolute;
    margin-top: 15px;
    margin-left: 20px;
}
.btn-view-by{
    height: 48px;
    border-radius: 0px;
    border: #000 2px solid;
    font-weight: 700;
}
.btn-view-by:hover{
    border: #000 2px solid !important;
}
.view-by-menu{
    align-items: center;
    margin-left: -57px !important;
    padding: 15px;
    border-radius: 20px;
    font-size: 24px;
}
.checklist-box{
    display: flex;
    margin: 10px 0;
}
.checkbox-option{
    width: 40px;
    height: 40px;
}
.filter-btn{
    margin-left: 20px;
}
.checklist_head{
    display: flex !important;
    justify-content: space-between;
    background-color: var(--blue);
    color: #fff;
    text-decoration: none;
    align-items: flex-start;
    font-size: 24px;
}
.checklist_head:hover{
    background-color: var(--blue) !important;
    color: #fff;
}
.right_options{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25%;
}
.custom_task_name{
    max-width: 20%;
}
.custom_DATE_PICKER{
    max-width: 20%;
}
.title_heading{
    text-decoration: none;
}
.title_heading:hover{
    color: #fff;
}
.dateTable{
    padding: 10px;
}
.title_heading1 {
    font-size: 21px;
    font-weight: 700;
    padding: 20px 0;
}
.datelisttable{
    border-top: none !important;
}
.contacts-person input{
    width: 425px;
}
.custom_plus_2{
    margin-left: 0;
    padding-left: 10px;
    min-width: 250px;
}
.custom_plus_3{
    min-width: 280px;
}
.budget-balance{
    display: flex;
}
.budget-balance-remaining{
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.budget-balance-total{
    display: flex;
    align-items: center;
    flex-direction: row;
}
 .balance-label{
    font-size: 14px;
    font-weight: 600;
    width: 220px;
    margin-bottom: 0;
}
.total_of_expanse{
    text-align: right;
    color: #000;
    padding: 5px 20px;
    margin-bottom: 0;
}

.accordion .table_height_va {
    height: 280px !important;
}

.datepicker_form .react-datepicker-wrapper .react-datepicker__input-container input{
    border: 1px solid #ced4da !important;
}

@media screen and(max-width:1024px){
    .add-newbutton {
        width: 50%;
    }
    
}
@media (max-width: 1199px){
    .right_options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: auto;
    }
    .css-nccuzw-MuiFormControl-root-MuiTextField-root {
        width: 100% !important;
    }
}
    
@media screen and (max-width: 991px){
    .custom_header .navbar-nav.me-auto.mb-2.mb-lg-0 li {
        text-align: left;
        padding-top: 10px;
    }
    #navbarSupportedContent {
        position: absolute;
        top: 70px;
        background-color: var(--blue);
        border: 1px solid #ccc;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        padding: 10px 10px 25px;
        z-index: 9;
    }
    .custom_tn8 .btn.btn-outline-success {
        color: #fff !important;
        width: 100%;
        text-align: left;
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .custom_tn8 .btn.btn-primary {
        background-color: #fff;
        padding: 10px 50px;
        border-radius: 50px;
        margin-left: 0;
        font-size: 18px;
        font-weight: 600;
        color: #3600c5;
    }
    .custom_tn8 {
        padding: 0px 10px;
        border-top: transparent;
        text-align: left;
    }
    .react-datepicker-popper {
        z-index: 9999 !important;
    }
    .toggle_outer .navbar-toggler-icon .fa {
        display: none;
    }
    .top_ma56 .input-group {

        display: inherit !important;

    }
}
@media screen and (max-width: 768px){
    .title_head_outer{
        max-width: 350px;
        text-align: left;
    }
}

@media screen and (max-width: 767px){
    .banner-btn {
        display: initial !important;
        margin-top: 50px;
    }
    .checklist_head {
        display: inherit;
        justify-content: space-between;
        text-decoration: none;
    }
    .right_options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 140px;
    }
    .btn.btn-link.checklist_head .title_heading {
        width: 100%;
        text-align: left;
        float: left;
    }
    .add-newbutton {
        width: 50% !important; 
    }
    .title_head_outer{
        max-width: 350px;
        text-align: left;
    }
    .right_options{
        flex-direction: column;
        align-items: flex-end;
    }
    .accordion .item .item-header h2 button.btn.btn-link{
        width: 50px;
        margin-top: 20px;
    }
    .right90 a{
        margin-right: 0;
        margin-bottom: 10px;
    }
    .delete_list{
        margin-right: 10px;
    }
    
}
@media screen and (max-width: 600px){
    .title_head_outer {
        max-width: 350px;
    }
}

@media screen and (max-width: 475px){
    .title_head_outer {
        max-width: 275px;
    }
}
.task-option{
    /* position: absolute ;
    margin-left: 60px;
    margin-top: -33px; */
    border: none;
    height: 40px;
    width: 250px;

}
.task-option:focus{
    border: 1px solid transparent;
    outline: none;
}
.task-option option{
    
}

.input_outer.date45{
    margin-bottom: 20px;
}



/* **********Model Csss************ */


.modal-heading{
    font-size: 32px;
    font-weight: 600;
}
.comments-btn{
    border: none imo !important;
    border-radius: 10px !important;
    background-color: var(--blue) !important;

}
.contacts-form-link{
    margin-left: 20px;     
    text-decoration: none;  
    color: #ADADAD;
}
.user-image-commentor{
    height: 40px;
    width: 60px;
}
.commenting-section{
    margin-top: 30px;
}
.form-header{
        padding: 20px 40px !important;
}

.col-top-mar{
    margin-top: 30px  !important;
}
.all-contacts-labels{
    font-size: 14px;
}
.input-text-placeholders{
    font-size: 14px;
}
.input-text-placeholders::placeholder{
    font-size: 14px;
}
.contacts-form-body{
    padding-left: 40px !important;
    padding-right: 40px !important;
 }
 .select-option-arrow{
    /* background-image: 
    url(
  "https://media.geeksforgeeks.org/wp-content/uploads/rk.png"); */
    /* background-image: url("images/arrowpurple.png") !important ; */
    background-size: 16px 10px !important;
}
.balance-board{
    background-color: rgba(253,217,85,0.4) !important;
    /* opacity: 0.3; */
    color: var(--blue) !important;
    font-size: 28px !important;
    font-weight: 700 !important;
    border: 1px solid var(--primary_yellow);
}
.balance-board::placeholder{
    color: var(--secondary_purple) !important;
}
.add-expense-btn{
    background-color: var(--blue);
    margin: auto;
    padding: 20px 50px;
    color: var(--white);
    border: none;
    border-radius: 30px;
}

.add-expense-btn:hover{
    background-color: var(--hover_blue);
    
}
.add-expense-footer{
    justify-content: center !important;
}
.remaining-budget-score{
    background-color: rgba(253,217,85,0.4) !important;
}

.summery_center{
    text-align: center;
    margin: 10px auto;
}
.mid_row{
    justify-content: center;
}
.category_label{
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 9px 12px;
    background-color: var(--primary_yellow);
    border-radius: 10px;
    display: inline-block;
}
.category_label_text{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0;
}
.clickme{
    background-color: var(--blue);
    border: none;
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
}
.clickme:hover{
    background-color: var(--hover_blue) !important;
    border: none !important;
    color: var(--white) !important;
}
.clickme:focus{
    background-color: var(--hover_blue) !important;
    border: none !important;
    color: var(--white) !important;
}
.center_align{
    text-align: center;
    justify-content: center;
}
.css-i4bv87-MuiSvgIcon-root{
    height: 2em !important;
    width: 2em !important;
}
.multi_drop{
    border: 3px solid #000;
    padding: 6px 10px;
    border-radius: 8px;
    margin: 20px auto;
    min-height: 50px;
    /* overflow-x: hidden; */
    max-width: 370px;
    cursor: pointer;
}
.multi_drop input::placeholder{
    color: #000  !important;
    font-size: 16px ;
    text-align: center !important;
    margin-left: 120px !important;
    font-weight: 600;   
}





/* ....................................sign Up page and login page************************************** */
.signup_container{
    display: flex;
    max-width: 850px;
    margin: 50px auto;
    border-radius: 20px;
    min-height: 500px;
}
.image_con{
    /* display: flex; */
    padding: 50;
    text-align: center;
    background-image: url("https://images.pexels.com/photos/50675/banquet-wedding-society-deco-50675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
    margin: 2px;
    flex: 0 0 50%;
    position: relative;
}
.motive_text{
    align-self: center;
    margin: auto;
    width: 100%;
    height: 100%;
    padding-top: 50%;
    background: rgba(21, 84, 187, 0.8); 
    color: var(--white);
    font-weight: 900;
    max-width: 500px;
    border-radius: 20px 0 0 20px;
    
}
.motive_text h2{
   width: 400px;
}
.highlight_form_text{
    font-size: 14px;
    color: #000;
    
}
.form_con{
    text-align: center;
    width: 100%;
}
.Forgot_P{
    text-decoration: none;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    color: var(--blue);
}

.action_form{
    margin: 60px 20px;
}
.action_form h2{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}



.F_name{
    background-image: url("../images/F_user.svg");
    background-repeat: no-repeat;
    background-size: 20px 30px;
    background-position:5px;
    padding-left: 30px;
    border: 2px solid #bad4fa;
}

.E_mail{
    background-image: url("../images/mail_outline.svg");
    background-repeat: no-repeat;
    background-size: 20px 30px;
    background-position:5px;
    padding-left: 30px;
    border: 2px solid #bad4fa;
}

.Country_name{
    background-image: url("../images/phone_I.svg");
    background-repeat: no-repeat;
    background-size: 20px 30px;
    background-position:5px;
    padding-left: 30px;
    border: 2px solid #bad4fa;
}
.P_Number{
    border: 2px solid #bad4fa;
}

.P_assword{
    background-image: url("../images/lock_Password.svg");
    background-repeat: no-repeat;
    background-size: 20px 30px;
    background-position:5px;
    padding-left: 30px;
    border: 2px solid #bad4fa;
}

.button_mg{
    width: 100%;
    background-color: var(--blue);
    border: none;
}
.button_mg:hover{
    width: 100%;
    background-color: var(--hover_blue);
}
.button_mg:focus{
    width: 100%;
    background-color: var(--hover_blue);
}



.multi_drop .optionListContainer {
    margin-left: -10px;
    margin-top: 20px;
}

.multi_drop .optionListContainer .optionContainer .option input{
    width: 18px;
    height: 18px;
}

.multi_drop .optionListContainer .optionContainer .option{
    font-size: 24px !important;
}
.country-list {
    text-align: left;
}
.Form_con_2{
    text-align: left;
}
.login_with_div{
    margin-top: 40px;
    border-top: 1px solid var(--grey_2);

}
.more_login_text{
    margin-top: -23px !important;
    padding: 10px 20px;
    background-color: var(--white);
    max-width: 150px;
    margin: auto;
}
.not_account{
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
}
.Login_with_link{
    margin-top: 20px;
}
.link_App{
    width: 50px;
    height: 50px;
    padding: 5px 17px;
    border-radius: 10px;
    font-size: 32px;
    text-decoration: none;
    color: var(--black);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}
.link_App:hover{
    color: var(--black);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 10px 30px 0 rgba(0, 0, 0, 0.19);
}
.link_App:focus{
    color: var(--black);
}

@media screen and (max-width:991px){

.signup_container{
    flex-direction: column;
}
.motive_text{
    border-radius: 20px;
    width: 100%;
    max-width: 992px;
    padding-top: 0;
}
.image_con{
    border-radius: 20px;
    height: 350px;
}
.motive_text h2{
    margin: auto;
    width: 80%;
}
.Motive_text_tell{
    text-align: center;
    padding: 15%;
}
.action_form{
    margin-top: 20px;
}

}
@media screen and (max-width:768px){
    .Motive_text_tell{
        text-align: center;
        padding: 70px 10px ;
    }
    .signup_container{
        margin: 20px auto;
    }
}

/* ..........................from now on Shubham..................   */

.if_bg_yelo{
    background-color: var(--blue);
    color: var(--white);
    font-size: 24px;
    padding: 25px 40px;
    font-weight: 600;
    border-radius: 30px;
    margin-top: 50px;
    border: none;
    outline: none;
}
.if_bg_yelo:hover{
    background-color: var(--hover_blue);
    color: var(--white);
}

.if_bg_yelo:focus{
    background-color: var(--hover_blue);
    color: var(--white);
}
.category_accordian{
    margin-top: 5px;
}
/* .title_head_outer{
    margin-top: 3px;
} */
.custom_ty66 {
    margin-top: 10px;
    cursor: pointer;
}
.logn_btn{
    text-decoration: none;
    color: #000;
}
.logn_btn2{
    text-decoration: none;
    color: #fff;
}
.logn_btn2:hover{
    text-decoration: none;
    color: #fff;
}
.only_Li{
    border: none;
    background-color: var(--blue) !important;
}
.only_Li:hover{
    border: none;
    background-color: var(--hover_blue) !important;
}
.check_heading{
    align-items: center;
    padding-bottom: 10px;
}
.assign_outer p {
    font-size: 14px;
    margin-bottom: 0;
}
.custom_table::-webkit-scrollbar {
    width: 5px !important;
  }
.table_gap{
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px 10px 10px 10px;

}
.table>thead{
    vertical-align: top;
}
.action_ul_2{
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    padding-left: 0px;
}
.action_ul_2 li{
    list-style: none;
}
.options1{
    text-decoration: none;
    color: var(--grey_2);
}
.options{
    text-decoration: none;
    color: var(--grey_2);
}
.react-tel-input .form-control{
    width: 100%;
    min-height: 38px;
    border: 2px solid #bad4fa;
}
.bottom_space{
    margin-bottom: 20px;
}
.profile_toggle::after{
    display: none;
}
.profile_toggle{
    background-color: transparent;
    border: none;
    border-radius: 50px;
    padding: 0;
}
.profile_toggle:hover{
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
}
.profile_toggle:focus{
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
}
.Profile_Option{
    padding: 10px 20px ;
    border-left: 3px solid var(--white);
}
.Profile_Option:hover{
    border-left: 3px solid var(--primary_yellow);

}
.Profile_icons{
    margin-right: 10px;
    color: #000;
    font-size: 24px;
}
.Profile_Options{
    margin-top: 10px;
}
.Add_more_event{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.react-datepicker__navigation{
    background-color: #f3f3f3 !important;
    color: #c5c5cf !important;
    width: 30px !important;
    padding: 15px !important;
}

.button_text_more button{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button_text_more .dropdown-menu{
    max-height: 250px;
    overflow-y: scroll;
    width: 300px;
}
.button_text_more .dropdown-menu .dropdown-item{
    white-space: pre-wrap;
}


@media screen and (max-width:991px){
    
    .logn_btn{
        text-decoration: none;
        color: #fff;
    }
    .logn_btn2{
        text-decoration: none;
        color: #000;
    }
    .only_Li{
        border: none;
        background-color: var(--primary_yellow) !important;
    }
    .only_Li:hover{
        border: none;
        background-color: var(--primary_yellow) !important;
    }
    .features-dropdown{
        background-color: var(--white)  !important;

    }
    .features-dropdown a{
        color: #000 !important;
        font-weight: 500 ;
        
    }
    .budget_table{
        overflow-x: scroll;
    }
    
}




@media screen and (max-width:767px){
    .m_top{
        margin-top: 40px !important;
    }
}


@media screen and (max-width:600px){
    
    .left_tabs{
        max-width: 100% !important;
    }

    .left_tabs ul {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .check_list {
        display: flex;
        flex-direction: column;
        width: 100%;
        
    }
    .check_list12{
        width: 100%;
        padding: 10px 0 !important;
    }
    .check_list12 a{
        font-size: 14px;
    }
}



@media screen and (max-width:530px){
    .budget-balance{
        flex-direction: column;
    }
    .budget-balance-remaining{
        margin-left: 0;
        margin-top: 20px;
    }
}






/* ********************** Profile Page Setup ************************ */
.pofile_Cont{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 50px 20px ;
    
}
.profl_div{
    padding: 20px;
    border-radius: 20px;
}


.profile_img img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid var(--white);
}
.Profile_Details {
    margin-top: 20px;
}
.Profile_Details p{
    color: var(--grey_2);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}
.Profile_Details p .fas{
    width: 20px;
    margin-right: 10px;
    text-align: center;
}
/* .Top_detail_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */
.H_btn{
    /* text-align: right; */
    margin-left: 24px;
}

.Detail_box{
    display: none;
}



@media (max-width:1170px){
    
    .Help-center-btn{
        font-size: 14px;
    }
    
}

@media screen and (max-width:991px){
    

    .detail_con{
        order: 4;
    }
    /* .Top_detail_box{
        display: flex;
        flex-direction: column;
        text-align: left;
    } */
    .H_btn{
        margin-top: 20px;
        text-align: left;
    }
    .detail_con{
        margin-top: 15px;
    }
    .Help-center-btn{
        font-size: 18px;
    }
    
.input-boxes-id{
    align-items: flex-start;
}
}
@media screen and (max-width:768px){
    
    .Detail_box{
        display: block;
    }
    .H_btn{
        margin-top: 0px;
        text-align: left;
        margin-left: 0;
    }
    .detail_con{
        margin-top: 15px;
    } 

}


/* -------------------------------- Payments Processing ------------------------------ */


.pay_back{
    background-color: var(--white);
    max-width: 2000px;
    width: 100%;
    padding: 50px;
    margin: auto;
}

.payment-balance{
    max-width: 1170px;
    border-radius: 30px;
    padding: 39px 48px;
    background-color: #fff1c1;
    margin: 20px auto 10px;
}
.blnc-heading{
    font-size: 24px;
    color: var(--blue);
}
.conference{
    font-size: 18px;
    margin-bottom: 0;
    color: var(--blue_dark_X);
}
.blnc-number{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--blue_dark);
}

.pay-option-head{
    font-size: 24px;
    color: var(--blue_dark_X);
}
.credit-card-option{
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    margin-top: 45px;
    background-color: var(--white);
}
.for-payment{
    font-size: 24px;
    color: var(--blue_dark);
    font-weight: 500;
    margin-left: 20px;
}
.paycard{
    display: flex;
    align-items: center;
}
.paycard .form-check-input{ 
    width: 33px;
    height: 33px;
    margin-top: 8px;
    color: var(--blue) !important;
}

.paycard .form-check-input:checked{ 
    color: var(--blue);
    background-color: var(--blue);
    border-color: var(--blue);
}
.card-details{
    margin-top: 45px;
}
.card-details-input-2{
   margin-top: 0px !important;
}
.card-details-input input{
    height: 50px;
    font-size: 24px;
    padding: 15px 30px;
    /* background-color: var(--input-bg-color); */
    /* border: 1px solid var(--input_border); */
    border-radius: 10px;
 }
 .pay-now{
    margin-top: 18px;
 }
 .pay-btn{
    background-color: var(--blue) ;
    border: none;
    padding: 12px 30px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
 }

 .pay-btn:hover{
    background-color: var(--hover_blue);
    
 }

.noted-point{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 41px;
}
.noted-point img{
    margin-right: 10px;
}
.deleete{
    cursor: pointer;
}

.su_btn_box{
    justify-content: flex-end;
}

.Subscription_btn{
    margin-top: 20px;
    text-align: right;
}


@media screen and (max-width:768px) {
    .pay_back{
        padding: 50px 20px;
    }
    .payment-balance{
        border-radius: 20px;
        padding: 20px;
        background-color: #fff1c1;
        margin: 20px;
    }
    .credit-card-option {
        justify-content: space-between;
        
    }
    .credit-card-option img{
        width: 50%;
        text-align: right;
        justify-content: flex-end;

    }

    
}

.sidebar_disabled {
    pointer-events:none;
    opacity:0.6;   
    text-decoration: none;
}     

.react-tel-input .special-label input{
    width: 100%;
}

.overlay{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.center_dots{
    position: absolute;
    top:50%;
    left: 45%;
}

.side_list_style .nav-item .active{
    background-color: #008afc;
    color: #fff;
  }

/* --------------------------------- Profile Edit Page ----------------------------------------- */

.Profile_edit{
    max-width: 800px;
    margin-top: 30px;
  }

  .Profile_img img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    background-color: var(--white);
   }
   
   .btn_get {
       display: block;
       max-width: 200px;
       /* margin: 0 auto; */
       background-color: var(--blue);
       border-radius: 8px;
       font-size: 1em;
       line-height: 2.5em;
       text-align: center;
       padding: 0px 10px;
       color: var(--white);
       font-weight: 600;
   }
   
   .btn_get:hover {
       background-color: var(--hover_blue);
   }
   
   .btn_get:active {
       background-color: var(--blue);
   }
   
   .get_file {
       border: 0;
       clip: rect(1px, 1px, 1px, 1px);
       height: 1px; 
       margin: -1px;
       overflow: hidden;
       padding: 0;
       position: absolute;
       width: 1px;
   }
   .Detail_text{
       color: var(--more_grey);
   }
   .Detail_text .fas{
       width: 15px; margin-right: 5px;
   }
   .btn-group{
    width: 100%;
    /* gap: 30px; */
    row-gap: 30px;
   }  
   .bton_check{
    background-color: transparent !important;
    width: 100%;
   }
   .btn_checkbox_option{
    background-color: var(--grey) !important;
    border-radius: 30px;
    padding: 20px;
    text-align: center;
    border: 2px solid var(--grey);
    position: relative;
    font-size: 24px;
    width: 100%;
    color: #000;
   }
   .btn_checkbox_option:hover{
    color: #000;
   }
   .btn_checkbox_option:focus{
        box-shadow: none !important;
   }

   .btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active{
        color: #000 !important;
        border-color: var(--blue);
        background-color: var(--blue);
   }
   .next_date input{
        height: 62px !important;
   }
/* ---------------------- Step 1 renew -------------------------------- */

.checkbox_getstarted{
    row-gap: 30px;
    align-items: center;
}
.Two_Choice  .row{
    row-gap: 15px;
}
.orp{
    font-size: 24px;
}

.tabberHost{
    justify-content: center;
    gap: 30px;
    text-align: center;

}
/* .tabberHost .nav-item .nav-link{
    padding:20px 70px;
    border-radius: 30px;
    margin-bottom: 20px;
    border: 1px solid var(--hover_blue);
    background-color: var(--grey);
    font-size: 32px;
    width: 100%;
    max-width: 300px;
    font-weight: 600;
} */

.tabberHost .nav-item:nth-child(1) .nav-link, .tabberHost .nav-item:nth-child(3) .nav-link{
    width: 100%;
    padding:15px 70px;
    border-radius: 30px;
    margin-bottom: 20px;
    border: 2px solid var(--grey);
    background-color: var(--grey);
    font-size: 32px;
    max-width: 300px;
    font-weight: 700;
    color: var(--black) ;
}

.tabberHost .nav-item:nth-child(1) .active, .tabberHost .nav-item:nth-child(3) .active{
    border: 2px solid var(--hover_blue);
    color: var(--black) ;
}
.tabberHost .nav-item:nth-child(1) .nav-link:hover, .tabberHost .nav-item:nth-child(3) .nav-link:hover{
    border: 2px solid var(--hover_blue);
    color: var(--black) ;
}


.tabberHost .nav-item:nth-child(2) .nav-link{
    width: 100%;
    padding:20px 70px;
    border-radius: 30px;
    margin-bottom: 20px;
    border: 1px solid var(--white);
    background-color: var(--white);
    font-size: 24px;
    max-width: 300px;
    font-weight: 400;
    text-transform: uppercase;
}


.Two_Choice .btn_checkbox_option{
    background-color: var(--grey) !important;
    border-radius: 30px;
    padding: 5px 20px ;
    text-align: center;
    border: 2px solid var(--grey);
    position: relative;
    font-size: 24px;
    width: 100%;
    color: #000;
    /* height: 100% !important; */
}
